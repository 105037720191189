import React from "react"
import { graphql } from "gatsby"

// import app components
import { FlexibleContent, Spacer } from "components"

export default function DefaultPageTemplate(props) {
  const {
    data: {
      page: {
        title,
        uri,
        templateDefault: { flexibleContent }
      }
    }
  } = props

  return (
    <>
      <Spacer pt={60} pb={60}>
        {!!flexibleContent && (
          <FlexibleContent
            rows={flexibleContent}
            data={{
              title,
              uri
            }}
          />
        )}
      </Spacer>
    </>
  )
}

export const CollectionQuery = graphql`
  query DefaultTemplate($id: String!) {
    page: wpPage(id: { eq: $id }) {
      templateDefault {
        flexibleContent {
          __typename
          ... on WpPage_Templatedefault_FlexibleContent_Text {
            text
          }
          ... on WpPage_Templatedefault_FlexibleContent_Form {
            formId
          }
        }
      }
      title
      slug
      uri
    }
  }
`
